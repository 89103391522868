

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

  }

@media screen and (max-width : 700px) {
    .FINTESA{
        font-size: 80%;
    }
    .ContactUs{
        font-size: 80%;
    }
}


