/** @format */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

body {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Lato', sans-serif;
}

p,
li,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Lato', sans-serif;
}

#root {
    min-height: 100vh;
}
#root>:first-child {
    min-height: 100vh;
}
code {
    font-family: sans-serif;
}


/* flip Partners */

.flip-box {
    width: 80%;
    height: 80%;
}

.flip-box-inner {
    position: relative;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-front2 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-box-back {
    color: black;
    transform: rotateY(180deg);
}


/* Scrolling Top */

.ss-go-top {
    z-index: 2;
    position: absolute;
    top: -2rem;
    display: flex;
    justify-content: right;
}

.ss-go-top svg {
    height: 2rem;
    width: 2rem;
}

.ss-go-top svg path {
    fill: #304352;
    transition: all 0.3s ease-in-out;
}

.ss-go-top a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 0 none;
    height: 3.2rem;
    width: 3.2rem;
    background-color: var(--color-gray-12);
    border-radius: 50%;
    border: 0.2rem solid #304352;
    transition: all 0.3s;
    position: relative;
    margin-right: 50px;
}


/* Log In Button */

.Loginbutton {
    border: none;
    color: #4d66f3;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.Lbutton {
    background-color: white;
    color: #304daf;
    border: 1.5px solid #304daf;
}

.Lbutton:hover {
    color: white;
    background-color: #d7dcf6;
}


/* product in Header */

[data-letters]:before {
    content: attr(data-letters);
    display: block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    margin-right: 1em;
    color: white;
    background-color: gray;
    box-shadow: 0 1px 5px 0 grey;
    cursor: 'pointer';
    font-weight: 600;
}

[data-letters-MC]:before {
    content: attr(data-letters-MC);
    display: block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    margin-right: 1em;
    color: white;
    background-color: #8085e3;
    box-shadow: 0 1px 5px 0 #8085e3;
    cursor: 'pointer';
    font-weight: 600;
}


/* Account Info link */

.Header_link {
    background-color: #fff;
    box-shadow: 0 2px 4px rgb(50 50 93 / 10%);
    text-decoration: none;
    color: #02203c;
    border-radius: 5px;
}

.Header_link_span {
    align-items: center;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
    font-size: 14px;
    text-align: center;
    padding: 10px 0 10px 0;
}

.Header_link:hover {
    background-color: rgba(235, 232, 232, 0.849);
    text-decoration: none;
    color: #02203c;
}