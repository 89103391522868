/** @format */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.item {
  padding: 1%;
}

.item:hover {
  border-width: 1%;
  border-bottom: solid blue;
  color: blue;

}

.item:focus {
  border-bottom: solid blue;
}

.item.active {
  color: red;
}

/* product in Header */
[data-letters]:before {
  content: attr(data-letters);
  display: block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  margin-right: 1em;
  color: white;
  background-color: gray;
  box-shadow: 0 1px 5px 0 grey;
  cursor: 'pointer';
  font-weight: 600;
}

[data-letters-MC]:before {
  content: attr(data-letters-MC);
  display: block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  margin-right: 1em;
  color: white;
  background-color: #8085e3;
  box-shadow: 0 1px 5px 0 #8085e3;
  cursor: 'pointer';
  font-weight: 600;
}

.MC .btn-info {
  display: block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  margin-right: 1em;
  color: white;
  background-color: #8085e3;
  box-shadow: 0 1px 5px 0 #8085e3;
  cursor: 'pointer';
  font-weight: 600;
}

.MC .btn-info:hover {
  background-color: #8085e3;
  box-shadow: 0 1px 5px 0 #8085e3;
  color: white;
}

/* Account Info link */
.Header_link {
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(50 50 93 / 10%);
  text-decoration: none;
  color: #02203c;
  border-radius: 5px;
  border-color: none;
}

.Header_link_span {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0 10px 0;
}

.Header_link:hover {
  background-color: rgba(235, 232, 232, 0.849);
  text-decoration: none;
  color: #02203c;
}

/* ------------------------------- end header prodect */